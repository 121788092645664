import React from "react";

export default function WelcomePage() {
  return (
    <div className="welcomepage">
      <marquee behavior="" direction="">
        Welcome to VAANAGAN EXPORTS. Website in progress, stay with us!
      </marquee>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="border p-4">
            <h3>Contact Address</h3>
            <h6>
              36/7-2, Sai Towers, <br /> 2nd Floor Room No.213, <br /> Maduram
              Nagar, <br />
              Kamaraj Road, Kumbakonam, <br /> Tamilnadu, SouthIndia - 612 001.
            </h6>
            <h5>IEC: AJKPR6827M</h5>
            <a href="tel:8610890039">+91-8610890039</a> <br />
            <a href="tel:8300623362">+91-8300623362</a> <br />
            <a href="mailto:vaanaganexports@gmail.com">
              vaanaganexports@gmail.com
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
