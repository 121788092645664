import {
  BrowserRouter as Router,
  Link,
  Routes,
  Route,
  Switch,
} from "react-router-dom";
import "./App.css";
import Home from "./Components/Home";
import Navbar from "./Components/NavbarNew";
import Contact from "./Components/Contact";
import WelcomePage from "./Components/WelcomePage";

function App() {
  return (
    <div>
      {/* <Router>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
        </Routes>
        <Contact />
      </Router> */}

      <Router>
        <Navbar />
        <WelcomePage />
      </Router>
    </div>
  );
}

export default App;
