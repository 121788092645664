import React from "react";

export default function NavbarNew() {
  return (
    <div className="navbar-new">
      <div className="container-fluid">
        <div className="row">
          <div className="col-6">
            <img
              src="./images/Vanagannewlogo.jpg"
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="col-6 align-self-center">
            <button
              data-toggle="modal"
              data-target="#exampleModal"
              className="btn btn-primary contact-btn"
            >
              Any Queries?
            </button>
          </div>
        </div>
      </div>

      <div
        class="modal fade "
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Contact Us
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {/* <div className="contact-home ">
                <h6>
                  <span>Address :</span> 36/7-2, Sai Towers, 2nd Floor, Room
                  No.213 Madhuram Nagar, Kamaraj Road, Kumbakonam. TamilNadu,
                  South India - 612 001.
                </h6>
                <h6 className="mt-4">
                  <span>Email : </span>{" "}
                  <a href="mailto:vaanaganexports@gmail.com">
                    vaanaganexports@gmail.com
                  </a>
                </h6>
                <h5>
                  <span>IECODE :</span> AJKPR6827M{" "}
                </h5>
                <hr />
                <h4>Any Queries?</h4>
              </div> */}

              <form action="https://docs.google.com/forms/d/e/1FAIpQLScc54cpw5Jcy_RRZ5cYl4MOjl-KYCNaYwml7AE8MGKtlqtAZg/formResponse">
                <div class="row">
                  <div class="col-md-6 mt-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Name"
                      name="entry.918866193"
                    />
                  </div>
                  <div class="col-md-6 mt-3">
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Mobile Number"
                      name="entry.1623804486"
                    />
                  </div>
                </div>
                <div class="row ">
                  <div class="col-md-6 mt-3">
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Mail-ID"
                      name="entry.1544299286"
                    />
                  </div>
                  <div class="col-md-6 mt-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Subject "
                      name="entry.980691433"
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div class="col-md-12">
                    <textarea
                      class="form-control"
                      name="entry.1033886945"
                      id=""
                      placeholder="Message"
                      cols="30"
                      rows="10"
                    ></textarea>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <button className="btn btn-primary">Submit</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
